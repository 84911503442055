<template>
  <v-menu
    content-class="m-0 p-0"
    transition="scale-transition"
    offset-y
    bottom
    nudge-bottom="5"
    auto
    rounded
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        class="mx-sm-1 mb-1 mr-0 pa-0"
        :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
      >
        <button v-bind="attrs" v-on="on" class="btn btn--export-filter">
          <span class="svg-icon">
            <v-icon size="18">mdi-cloud-upload</v-icon>
          </span>
          {{ $t("import") }}
        </button>
      </div>
    </template>
    <div class="py-2 w-150px bg-white px-3">
      <div
        class="drop-item poppins px-3 rounded-lg cursor-pointer my-4 bg-light bg-hover-light-info"
        v-for="(data, index) in types"
        :key="index"
        @click="actionFunction(data.type)"
      >
        <div class="py-2 w-100 poppins font-size-md text-center">
          {{ data.name }}
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
// import Swal from "sweetalert2";
export default {
  name: "UploadSelect",
  props: ["types", "actionFunction"],

  methods: {},
  computed: {},
};
</script>
